import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import GTM from "@src/services/gtm"
import PrimaryLayout from "@src/layouts/primary"
import PageHelmet, { PageSeoPropTypes } from "@src/components/page-helmet"
import { normalizeTitle } from "@src/utils/page-data"
import Image from "@src/components/core-image"
import ImageWrapper from "@src/components/core-image-wrapper"
import { SITE_DOMAIN } from "@src/utils/constants"
import Button from "@src/components/core-button"

const StyledSection = styled.section`
  background-color: #26383b; // Natasha style - refactor to use central theme with new design system
  padding: 48px 24px 42px;

  // Calculate fold min-height using height of responsive site header
  ${props => `min-height: calc(100vh - ${props.theme.header.tablet.height}px);`}
  @media only screen and (${props => props.theme.header.small.min}) {
    ${props =>
      `min-height: calc(100vh - ${props.theme.header.small.height}px);`}
  }
  @media only screen and (${props => props.theme.header.large.min}) {
    ${props =>
      `min-height: calc(100vh - ${props.theme.header.large.height}px);`}
  }

  & > * {
    font-family: system-ui;
    letter-spacing: 0.5px;
    color: ${props => props.theme.white};
    margin: auto;
    max-width: 345px;
  }

  & > div.thank-content {
    display: flex;
    flex-direction: column;
    & > h1.thank-title {
      font-family: system-ui;
      font-weight: 900;
      font-size: 2rem;
      line-height: normal;
      letter-spacing: normal;
      margin: 0;
    }

    & > p.thank-message {
      color: #64d396;
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.5rem;
      order: -1;
      margin-bottom: 16px;
    }

    & > p.thank-info {
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
      margin-top: 24px;
      margin-bottom: 32px;
    }

    & > a {
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.125rem;
      font-weight: 700;
      line-height: 1.5rem;
      &.tours {
        &:hover {
          background-color: #fedb4d;
        }
        &:focus {
          background-color: #cb9c0e;
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05),
            0px 0px 0px 4px rgba(203, 156, 14, 0.57);
        }
      }
      &.blog {
        background-color: transparent;
        border: 1px solid ${props => props.theme.white};
        margin: 16px 0 32px;
        &:hover {
          color: black;
          background-color: #d3d4db;
          border: 1px solid #315254;
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        }
        &:focus {
          background-color: transparent;
          color: #d3d4db;
          border-color: #d3d4db;
        }
      }
    }
  }

  // Image dimensions
  & > div:last-of-type {
    height: 348px;
    width: 345px;
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    display: grid;
    grid-template-columns: minmax(auto, 469px) minmax(auto, 640px);
    align-items: center;
    justify-items: center;
    justify-content: center;
    & > div.thank-content {
      display: block;
      grid-column: 1 / 2;
    }
    & > div:last-of-type {
      grid-column: 2 / 3;
      margin: 0;
    }
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    grid-gap: 50px;
    & > div:last-of-type {
      max-width: none;
      height: 592px;
      width: 640px;
    }
  }
  @media only screen and (${props => props.theme.screen.large.min}) {
    & > div.thank-content {
      max-width: 470px;
      & > a {
        display: inline-flex;
        &.tours {
          max-width: 214px;
        }
        &.blog {
          max-width: 172px;
          margin: 0 0 0 24px;
        }
      }
    }
  }
`

const PageTemplate = ({ data }) => {
  const {
    tenAdventures: {
      page: { uri: pageUri, seo: pageSeo },
    },
  } = data
  GTM.dataLayerPushPageInfo({
    template: "static-content",
  })
  return (
    <PrimaryLayout>
      <PageHelmet
        title={normalizeTitle(pageSeo.title)}
        meta={{
          canonical: `${SITE_DOMAIN}${pageUri}`,
          description: pageSeo.metaDesc,
          robots: [pageSeo.metaRobotsNofollow],
          image: {
            alt: "Thank You For Booking 10Adventures",
            url: `${SITE_DOMAIN}/images/booking-thank-you-image.svg`,
            height: 640,
            width: 592,
          },
          openGraph: {
            type: pageSeo.opengraphType,
            modifiedTime: pageSeo.opengraphModifiedTime,
          },
          twitter: {
            data: [],
          },
        }}
      />
      <StyledSection>
        <div className="thank-content">
          <h1 className="thank-title">Request received</h1>
          <p className="thank-message">
            Thank you for submitting your booking request!
          </p>
          <p className="thank-info">
            Our team will get to work on confirming availability for your tour,
            and we’ll be in touch by email within 1 business day. While we know
            you’re excited to confirm your travel plans, please do not book your
            flights until your tour is confirmed.
          </p>
          <Button className="tours" to="/tours/">
            Browse more tours
          </Button>
          <Button className="blog" to="/blog/">
            Read the blog
          </Button>
        </div>
        <Image
          wrapper={ImageWrapper}
          src="/images/booking-thank-you-image.jpg"
          alt="Thank You For Booking 10Adventures"
        />
      </StyledSection>
    </PrimaryLayout>
  )
}
PageTemplate.propTypes = {
  data: PropTypes.shape({
    tenAdventures: PropTypes.shape({
      page: PropTypes.shape({
        uri: PropTypes.string,
        seo: PropTypes.shape({ ...PageSeoPropTypes }),
      }),
    }),
  }),
}
export default PageTemplate
export const query = graphql`
  query ($id: ID!) {
    tenAdventures {
      page(id: $id, idType: DATABASE_ID) {
        uri
        seo {
          ...TenAdventuresGQL_PostSEO
        }
      }
    }
  }
`
